/*
   サイドメニュー
*/

// ページ読込前に遷移させない処理
$('.c-sidebar-nav-dropdown-toggle').on('click', () => {
  return false
})

$(() => {
  function check_env() {
    const ua = navigator.userAgent.toLowerCase()
    const is_ipad = (ua.indexOf('ipad') > -1 || ua.indexOf('macintosh') > -1 && 'ontouchend' in document)
    const is_android_tablet = (ua.indexOf('android') > -1 && ua.indexOf('mobile') === -1)
    const is_touchscreen_device = 'ontouchend' in document
    const ua_value = ua.indexOf('iphone') > -1 || ua.indexOf('android') > -1 && ua.indexOf('mobile') > -1 || is_android_tablet || is_ipad || is_touchscreen_device || ua.indexOf('android') > -1 ? 'no_desktop' : 'desktop'

    // alert(ua_value)
    return ua_value
  }

  $.fn.fixpos = () => {
    if (!$('.c-sidebar-nav-dropdown-active').length) {
      return false
    }

    const $sidebarNavDropdownActive = $('.c-sidebar-nav-dropdown-active')
    const logoHeight = $('.c-sidebar-brand').outerHeight() ? $('.c-sidebar-brand').outerHeight() : 70
    const posTop = $sidebarNavDropdownActive.offset().top - $(window).scrollTop()
    const wHeight = $(window).height()
    const $dropdown = $sidebarNavDropdownActive.closest('.c-sidebar-nav-dropdown')
    const $dropdownItem = $sidebarNavDropdownActive.find('.c-sidebar-nav-dropdown-items')
    const dropdownItemHeight = $dropdownItem.height()
    const thisHeight = $sidebarNavDropdownActive.outerHeight()
    let dropdownItemTop = posTop + 'px'
    if (posTop > (wHeight / 2)) {
      dropdownItemTop = (posTop - dropdownItemHeight + thisHeight) + 'px'
    }

    $dropdownItem.css('top', dropdownItemTop)

    if (event.type === 'scroll' && $('.c-sidebar').hasClass('component-sidebar') === false && $(window).width() > 992) {
      if (posTop <= logoHeight) {
        $dropdown.not('.c-sidebar-nav-dropdown-toggle-allmenu').removeClass('c-show')
      } else {
        $dropdown.not('.c-sidebar-nav-dropdown-toggle-allmenu').addClass('c-show')
      }
    }
  }

  // サイドバーメニュークリック時の子メニューの位置調整
  $('.c-sidebar-nav-dropdown-toggle').on('click', function () {
    const ele = $(this)
    const $dropdown = ele.closest('.c-sidebar-nav-dropdown')
    if ($dropdown.hasClass('c-sidebar-nav-dropdown-active')) {
      $dropdown.removeClass('c-sidebar-nav-dropdown-active')
    } else {
      $('.c-sidebar-nav-dropdown').removeClass('c-sidebar-nav-dropdown-active')
      $dropdown.addClass('c-sidebar-nav-dropdown-active')
      $.fn.fixpos()
    }
  })

  // サイドメニュー スクロール時の子メニューの位置調整
  $('.c-sidebar-nav').on('scroll', () => {
    if ($('.c-sidebar-nav-dropdown-active').length) {
      $.fn.fixpos()
    }
  })

  // サイドメニュー以外クリック
  $(document).on('click', e => {
    if (!$(e.target).closest('.c-sidebar').length) {
      $('.c-sidebar-nav-dropdown-modal.c-show').removeClass('c-show')
    }
  })

  function iconNavScroll() {
    let navScrTop
    let navEvent
    let navScrIndex
    $('.c-sidebar-nav').css('cssText', 'overflow:hidden !important')
    $('.c-sidebar-nav-title').css({ overflow: 'initial' })

    function iconNavPos(nav) {
      const $nav = $(nav)
      let navTotalHeight = 0
      let navCurrentHeight = 0
      let navNavTitPosTop = 0
      $('.c-sidebar-nav-title').each((index, el) => {
        const $el = $(el)
        const titleHeight = $el.outerHeight()

        const dropdownHeight = $el.next().outerHeight()

        $el.nextAll().each(function () {
          if ($(this).hasClass('c-sidebar-nav-dropdown')) {
            navTotalHeight += dropdownHeight
            navCurrentHeight += dropdownHeight
          } else {
            return false
          }
        })
        navCurrentHeight += titleHeight
        navTotalHeight += titleHeight
        navNavTitPosTop = navTotalHeight - navCurrentHeight

        if (navNavTitPosTop <= $nav.scrollTop() && $nav.scrollTop() < navTotalHeight) {
          navScrIndex = index
        }

        navCurrentHeight = 0
      })
    }

    $('.c-sidebar-nav').on('scroll', function () {
      iconNavPos(this)
      if (navEvent === 'mouseenter') {
        navScrTop = $(this).scrollTop()
      }
    })
    $('.c-sidebar').on({
      mouseenter() {
        navEvent = 'mouseenter'
        $('.c-sidebar-nav').scrollTop(navScrTop)
      },
      mouseleave() {
        const h = $('.c-sidebar-nav-title').outerHeight()
        navEvent = 'mouseleave'
        if ($(this).hasClass('c-sidebar-unfoldable')) {
          $('.c-sidebar-nav').scrollTop(h * navScrIndex)
        }
      }
    })
  }

  // サイドメニュー縮小時スクロール
  // iconNavScroll()

  // 縮小時のホバー崩れ対応
  function minimizer() {
    // const tmpPos = 0
    // const psRailYTop = 0
    const $sidebar = $('.c-sidebar')
    $sidebar.find('.c-sidebar-minimizer').hover(
      () => {
        // $sidebar.find('.ps__rail-y').removeClass('top-0')
        // tmpPos = $sidebar.find('.c-sidebar-nav').scrollTop()
        $sidebar.find('.c-sidebar-nav').removeClass('ps--active-y')
        // psRailYTop = $sidebar.find('.ps__rail-y').css('top')
        // $sidebar.find('.ps__rail-y').data('top', psRailYTop)
      },
      () => {
        $sidebar.find('.c-sidebar-nav').addClass('ps--active-y')
        //   // $sidebar.find('.c-sidebar-nav').scrollTop(tmpPos)
        //   $sidebar.find('.ps__rail-y').removeClass('top-0')

      //   if ($sidebar.hasClass('c-sidebar-unfoldable')) {
      //     $sidebar.find('.ps__rail-y').addClass('top-0')
      //   }
      })

    $(window).on('resize', function () {
      if ($sidebar.hasClass('c-sidebar-unfoldable')) {
        if ($(this).width() > 992) {
          $sidebar.removeClass('c-sidebar-show')
        } else {
          $sidebar.addClass('c-sidebar-show')
        }
      }
    })
    // $('.c-sidebar-minimizer').on('click', () => {
    //   if ($sidebar.hasClass('c-sidebar-unfoldable')) {
    //     tmpPos = $sidebar.find('.c-sidebar-nav').scrollTop()
    //   } else {
    //     $sidebar.find('.c-sidebar-nav').scrollTop(tmpPos)
    //   }
    // })
  }

  minimizer()

  // サイドメニュー表示 初期処理
  function cSlidebarShowInit() {
    $('.c-header-toggler').removeClass('d-lg-none c-class-toggler')
    $('.c-sidebar-minimizer').removeClass('c-class-toggler')
    $('.c-sidebar-minimizer').on('click', () => {
      if ($('.c-sidebar-lg-show').length) {
        $('.c-sidebar').removeClass('c-sidebar-lg-show c-sidebar-show')
        $('.c-sidebar-backdrop').remove()
      } else {
        $('.c-sidebar').addClass('c-sidebar-lg-show c-sidebar-show')
      }
    })
    $('.c-header-toggler').on('click', () => {
      if ($('.c-sidebar-lg-show').length) {
        $('.c-sidebar').removeClass('c-sidebar-lg-show c-sidebar-show')
        $('.c-sidebar-backdrop').remove()
      } else {
        $('.c-sidebar').addClass('c-sidebar-lg-show c-sidebar-show')
        $('body').append('<div class="c-sidebar-backdrop c-fade c-show"></div>')
      }
    })
    $(document).on('click', '.c-sidebar-backdrop', function () {
      $('.c-sidebar').removeClass('c-sidebar-lg-show c-sidebar-show')
      $(this).remove()
    })
  }

  // サイドメニュー表示 切り替え処理
  function cSlidebarShowToggle() {
    if ($(window).outerWidth() < 992) {
      $('.c-sidebar').removeClass('c-sidebar-lg-show c-sidebar-show')
      $('.c-sidebar-backdrop').remove()
    } else {
      $('.c-sidebar').addClass('c-sidebar-lg-show c-sidebar-show')
    }
  }

  function cSlidebarToggleBtn() {
    const $header = $('.c-header')
    const $sidebar = $('.c-sidebar')
    const $headerToggler = $header.find('.c-header-toggler')
    const $headerTogglerIcon = $headerToggler.find('.c-icon')
    const $sidebarMinimizerIcon = $sidebar.find('.c-sidebar-minimizer i')
    const sidebarCloseText = 'サイドメニューを折りたたむ'
    const sidebarOpenText = 'サイドメニューを展開する'
    $headerTogglerIcon.attr('data-original-title', sidebarCloseText).tooltip()
    $sidebarMinimizerIcon.attr('data-original-title', sidebarCloseText).tooltip()

    // $sidebar.on('transitionstart webkitTransitionStart', () => {
    //   setTimeout(() => {
    //     $('#' + $headerTogglerIcon.attr('aria-describedby')).remove()
    //     $('#' + $sidebarMinimizerIcon.attr('aria-describedby')).remove()
    //   }, 250)
    // })

    $sidebar.on('transitionend webkitTransitionEnd', () => {
      // eslint-disable-next-line no-negated-condition
      if ($sidebar.css('margin-left') !== '0px') {
        $headerTogglerIcon.attr('data-original-title', sidebarOpenText)
      } else {
        $headerTogglerIcon.attr('data-original-title', sidebarCloseText)
      }
    })
  }

  $(window).on('resize', () => {
    cSlidebarShowToggle()
  })
  if (check_env() === 'desktop') {
    cSlidebarToggleBtn()
  }

  cSlidebarShowInit()
  cSlidebarShowToggle()
})
